@import "../mixins/media";
@import "../mixins/projects";
/*
フッター

全ページで使用されるフッターのプロジェクトコンポーネントです。

ex [トップページ　フッター](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/11.8.footer.pug
+ec-footerRole

Styleguide 11.3
*/
.ec-footerRole{
  border-top: 1px solid #7d7d7d;
  margin-top: 30px;
  background: $color_main;

  @include media_desktop(){
    padding-top: 40px;
    margin-top: 100px;
  }
  & &__inner{
    @include media_desktop {
      @include container;
    }
  }
}

/*
フッターナビ

フッタープロジェクトで使用するナビゲーション用のコンポーネントです。

Markup:
include /assets/tmpl/elements/11.8.footer.pug
+ec-footerNav

sg-wrapper:
<div class="ec-footerRole">
  <div class="ec-footerRole__inner">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.3.1
*/
.ec-footerNavi{
  padding: 0;
  color: white;
  list-style: none;
  text-align: center;

  & &__link{
    display: block;

    @include media_desktop {
      display: inline-block;
    }

    a{
      display: block;
      border-bottom: 1px solid #7d7d7d;
      padding: 15px 0;
      font-size: 14px;
      color: inherit;
      text-decoration: none;

      @include media_desktop {
        display: inline-block;
        border-bottom: none;
        margin: 0 10px;
        padding: 0;
        text-decoration: underline;
      }
    }
    &:hover {
      a {
        opacity: .8;
        text-decoration: none;
      }

    }

  }
}

/*
フッタータイトル

フッタープロジェクトで使用するタイトル用のコンポーネントです。

Markup:
include /assets/tmpl/elements/11.8.footer.pug
+ec-footerTitle

sg-wrapper:
<div class="ec-footerRole">
  <div class="ec-footerRole__inner">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.3.2
*/
.ec-footerTitle{
  padding: 40px 0 60px;
  text-align: center;
  color: white;

  @include media_desktop {
    padding: 50px 0 80px;
  }

  & &__logo{
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
    @include reset_link();

    a{
      font-size: 22px;
      color: inherit;
      @include media_desktop {
        font-size: 24px;
      }

    }

    &:hover {
      a {
        opacity: .8;
        text-decoration: none;
      }
    }
  }
  & &__copyright{
    font-size: 10px;

    @include media_desktop {
      font-size: 12px;
    }
  }
}
