@import "variables";

// ---------------------------------------
// block style
// ---------------------------------------

// header
.navbar-light .navbar-brand {
  color: $color_main;
  font-weight: 100;
  font-size: 24px;
}
.navbar-light .navbar-toggler {
  border-color: transparent;
}
@media (max-width: 991.98px) {
  .navbar-collapse {
    background: #fafafa;
    margin-left: -15px;
    margin-right: -15px;
    padding: 15px;
  }
}
.navbar-light .navbar-nav .nav-link {
  color: #222;
  font-size: 14px;
  position: relative;
}
.header i.fas.fa-shopping-cart {
  position: relative;
}
.header .ec-cartNavi__badge {
  width: 20px;
  display: inline-block;
  height: 20px;
  background: #E94451;
  border-radius: 50%;
  text-align: center;
  color: #fff;
  padding: 3px 0;
  font-size: 10px;
  position: absolute;
  top: 0px;
  right: -4px;
}

// dropdown
.dropdown-item a {
  color: #212529;
}
.dropdown-submenu {
  position: relative;
}
.dropdown-submenu .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -1px;
}
.dropdown:hover>.dropdown-menu,
.dropdown-submenu:hover>.dropdown-menu {
  display: block;
}
@media (max-width: 991.98px) {
  .dropdown-menu {
    display: block;
    background-color: transparent;
    border: none;
  }
}
.dropdown-toggle::after {
  content: none;
}
.dropdown-item a {
  color: $color_main;
  font-size: 14px;
  &:hover {
    text-decoration: none;
  }
}
.dropdown-menu {
  padding: 5px 0;
}
.dropdown-menu.dropdown-menu-right {
  padding: 10px 15px;
}
// icon
.feather.feather-chevron-down {
  height: 16px;
}

// card
.ec-newItemRole {
  padding: 40px 0;
  .rank-num {
    width: 26px;
    height: 26px;
    background-color: $color_main;
    color: #ffffff;
    text-align: center;
    display: inline-block;
    margin-bottom: 15px;
  }
}
.card-item {
  color: $color_main;
  display: block;
  margin-bottom: 30px;
  &:hover {
    color: $color_main;
    text-decoration: none;
  }
  .title {
    margin-top: 10px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: bold;
  }
  .price  {
    margin-top: 5px;
    margin-bottom: 0;
    font-size: 14px;
  }
}
.ec-inlineBtn--top {
  padding: 12px 80px;
}

// news
.ec-newsRole {
  padding: 60px 0 0;
  background-color: $color_section;
  .ec-newsRole__newsTitle {
    font-weight: normal;
  }
  .ec-newsRole__news {
    border: none;
    background: #fff;
  }
}