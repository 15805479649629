@import "../mixins/media";
@import "../mixins/variables";
@import "../mixins/projects";

@mixin pager(){
}
/*
検索・一覧表示

検索欄や、一覧表示に使用するスタイル群です。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 7.2
*/

/*
トピックパス

検索結果で表示されるトピックパスのスタイルです。

ex [商品一覧ページ　横並びリスト部分](http://demo3.ec-cube.net/products/list?category_id=&name=)

Markup:
include /assets/tmpl/elements/7.2.search.pug
+ec-topicpath

Styleguide 7.2.1
*/
.ec-topicpath{
  letter-spacing: -.4em;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  -webkit-padding-start: 0;
  border-top: 1px solid $color_border;
  border-bottom: 1px dotted $color_border;
  padding: 10px;
  list-style: none;
  overflow: hidden;
  font-size: 12px;
  color: $color_sub;
  @include media_desktop {
    padding: 30px 0 10px;
    border: 0;
    font-size: 16px;
  }

  & &__item {
    @include reset_link();
  }
  & &__divider{
    color: #000;
  }
  & &__item,
  & &__divider,
  & &__item--active{
    display: inline-block;
    min-width: 16px;
    text-align: center;
    position: relative;
    letter-spacing: normal;
  }
  & &__item--active{
    font-weight: bold;
    @include reset_link();
  }
}

/*
ページャ

検索結果で表示される商品一覧のスタイルです。

ex [商品一覧ページ　ページャ部分](http://demo3.ec-cube.net/products/list?category_id=&name=)

Markup:
include /assets/tmpl/elements/7.2.search.pug
+ec-pager

Styleguide 7.2.2
*/
.ec-pager{
  list-style: none;
  list-style-type: none;
  margin: 0 auto;
  padding: 1em 0;
  text-align: center;
  & &__item,
  & &__item--active{
    display: inline-block;
    min-width: 29px;
    padding: 0 3px 0 2px;
    text-align: center;
    position: relative;
    @include reset_link();
    a{
      color: inherit;
      display: block;
      line-height: 1.8;
      padding: 5px 1em;
      text-decoration: none;
    }
    a:hover{
      color: inherit;
    }
  }
  & &__item--active {
    background: $clrGray;
  }
  & &__item:hover{
    background: $clrGray;
  }

}
